body, html {
  margin: 0;
  padding: 0;
}

ol,ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
  }
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}