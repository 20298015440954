a {
  color: var(--highlight-color);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--heading-color);
  font-family: var(--font-family-heading);
}
p {
  color: var(--text-color);
  font-family: var(--font-family-paragraph);
  font-size: 18px;
  line-height: 24px;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    list-style: none;
  }
}

.content {
  font-size: 18px;
  p {
    font-size: 18px;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 40px;
    line-height: 1.6;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 28px;
    line-height: 1.4;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
    &:first-of-type {
      margin-top: 0;
    }
    @include breakpoint('small') {
      font-size: 30px;
      line-height: 1.4;
    }
  }
  h3 {
    font-size: 22px;
    line-height: 1.4;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  ul {
    list-style: disc;
    li {
      list-style: disc;
    }
  }
  ol {
    list-style: decimal;
    li {
      list-style: decimal;
    }
  }
  ul,
  ol {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 20px;
    li {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 10px;
      margin-left: 0;
      @include breakpoint('small') {
        margin-left: 20px;
      }
    }
    @include breakpoint('small') {
      margin-left: 20px;
    }
  }
  a {
    text-decoration: underline;
    &:hover {
    }
  }
  blockquote {
    border-left: 5px solid var(--highlight-color);
    margin: 30px 0;
    padding: 20px;
  }
  blockquote p {
    font-size: 20px;
    display: inline;
    color: var(--color-base-text);
  }
  img {
    margin: 40px 0 40px 0;
    max-width: 100%;
    height: auto;
  }
  figure {
    margin: 2.6rem 0 1.18rem;
    img {
      margin: 0;
    }
    figcaption {
      margin-top: 10px;
      h4 {
        margin: 0;
        font-size: 1rem;
        font-weight: bold;
      }
      p {
        margin: 0;
      }
    }
  }
  code,
  pre {
    font-family: var(--font-family-monospace);
  }
  .highlight {
    pre {
      border-radius: 3px;
      font-size: 14px;
      line-height: 18px;
      padding: 30px;
      overflow: auto;
      font-family: var(--font-family-monospace);
    }
  }
}

.dot {
  color: var(--dot-color);
}

.footnotes {
  ol {
    list-style: decimal;
    li {
      list-style: decimal;
    }
  }
}