:root {
  --font-family-heading: "Jost";
  --font-family-paragraph: "Source Sans 3";
  --font-family-monospace: monospace;
  --base-color: #f0f0f0;
  --base-offset-color: #eaeaea;
  --highlight-color: #02a7f2;
  --heading-color: #3c4b52;
  --text-color: #222222;
  --dot-color: #7b16ff;
}

$breakpoints: (
  'small': 767px,
  'medium': 992px,
  'large': 1200px,
);

@import 'normalize';
@import 'breakpoints';
@import 'header';
@import 'footer';
@import 'content';
@import 'intro';
@import 'summary';
@import 'author';
@import 'pagination';
@import 'main-menu';
@import 'main-menu-mobile';
@import 'pygments/github';

html, body {
  box-sizing: border-box;
}

body {
  background: var(--base-color);
  font-family: var(--font-family-paragraph);
  color: var(--text-color);
  margin: 0;
  padding: 20px;
  overflow-x: hidden;
  @include breakpoint('small') {
    padding: 50px;
  }
  &.frame {
    min-height: 100vh;
    border: 18px solid var(--highlight-color);
  }
}

.wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
