.intro {
  margin-bottom: 60px;
  h1 {
    font-size: 48px;
    line-height: 56px;
    margin-top: 0;
    margin-bottom: 30px;
    @include breakpoint('small') {
      width: 80%;
      font-size: 88px;
      line-height: 94px;
      margin-bottom: 50px;
    }
    strong {
      color: var(--highlight-color);
    }
  }
  h2 {
    font-size: 50px;
    line-height: 56px;
    @include breakpoint('small') {
      width: 80%;
    }
  }
  p {
    font-size: 20px;
    line-height: 26px;
    @include breakpoint('small') {
      width: 80%;
      font-size: 22px;
      line-height: 32px;
    }
  }
  .read-more {
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    @include breakpoint('small') {
      font-size: 22px;
      line-height: 32px;
    }
  }
  img {
    max-width: 100%;
  }
  // img {
  //   margin-right: -30px;
  //   width: 100vw;
  //   vertical-align: bottom;
  //   @include breakpoint('small') {
  //     margin-left: -100px;
  //     margin-right: -100px;
  //     margin-bottom: 50px;
  //     width: auto;
  //   }
  // }
}
