.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  font-size: 16px;
  @include breakpoint('small') {
    padding-bottom: 5px;
    margin-bottom: 40px;
  }
  a.header-logo {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 30px;
  }
}


.hamburger-trigger {
  height: 30px;
  display: flex;
  align-items: center;
  @include breakpoint('medium') {
    display: none;
  }
}

.hamburger {
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: 0;
  transition: all 0.25s ease-in-out;
  color: var(--highlight-color);
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: currentColor;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-in-out;
  }
  &:hover {
    opacity: 0.7;
  }
  &.is-active {
    border-color: transparent;
    z-index: 100;
    color: var(--base-color);
    &:hover {
      opacity: 1;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
