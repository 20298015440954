.menu-main-mobile {
  position: fixed;
  background: var(--highlight-color);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: none;
  justify-content: center;
  flex-direction: column;
  &.open {
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 10;
    display: flex;
    li {
      animation: fadeInTop 0.5s ease forwards;
      animation-delay: 0.1s;
      &:nth-of-type(2) {
        animation-delay: 0.15s;
      }
      &:nth-of-type(3) {
        animation-delay: 0.20s;
      }
      &:nth-of-type(4) {
        animation-delay: 0.25s;
      }
      &:nth-of-type(5) {
        animation-delay: 0.30s;
      }
      &:nth-of-type(6) {
        animation-delay: 0.35s;
      }
    }
  }
  ul {
    font-size: 2rem;
    font-family: var(--font-family-heading);
    text-align: center;
    list-style: none;
    margin: 0;
    flex: 0;
    li {
      display: block;
      position: relative;
      opacity: 0;
      a {
        display: block;
        position: relative;
        color: var(--base-color);
        text-decoration: none;
        &:hover {
          opacity: 0.7;
        }
      }
      ul.sub-menu {
        font-size: 1.2rem;
        li {
          a {

          }
        }
      }
    }
  }
}

.hamburger-trigger {
  height: 30px;
  display: flex;
  align-items: center;
  @include breakpoint('medium') {
    display: none;
  }
}

.hamburger {
  border: none;
  cursor: pointer;
  outline: none;
  position: relative;
  display: block;
  width: 30px;
  height: 20px;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: 0;
  transition: all 0.25s ease-in-out;
  color: var(--highlight-color);
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: currentColor;
    transform: translate(-50%, -50%);
    transition: transform 0.25s ease-in-out;
  }
  &:hover {
    opacity: 0.7;
  }
  &.is-active {
    border-color: transparent;
    z-index: 100;
    color: var(--base-color);
    &:hover {
      opacity: 1;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@keyframes fadeInTop {
  0% {
    opacity: 0;
    top: 20%;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
